import { Controller } from "@hotwired/stimulus"

interface PromptItem {
  role: string;
  content: string;
}

export default class extends Controller {
  static targets = ["promptRows", "promptJson", "rowTemplate"]

  declare readonly promptRowsTarget: HTMLElement
  declare readonly promptJsonTarget: HTMLInputElement
  declare readonly rowTemplateTarget: HTMLTemplateElement

  connect(): void {
    // Initialize the hidden JSON field with the current prompt data
    this.updatePromptJson();
  }

  addPromptRow(event: Event): void {
    event.preventDefault()
    const newRow = this.rowTemplateTarget.content.cloneNode(true) as HTMLElement
    this.promptRowsTarget.appendChild(newRow)
    this.updatePromptJson()
  }

  removeRow(event: Event): void {
    event.preventDefault()
    const target = event.target as HTMLElement
    const row = target.closest('.prompt-row')
    if (row) {
      row.remove()
      this.updatePromptJson()
    }
  }

  updatePromptJson(): void {
    const promptData: PromptItem[] = []
    this.promptRowsTarget.querySelectorAll('.prompt-row').forEach((row) => {
      const roleSelect = row.querySelector<HTMLSelectElement>('select[name="ai_prompt[prompt][][role]"]')
      const contentTextarea = row.querySelector<HTMLTextAreaElement>('textarea[name="ai_prompt[prompt][][content]"]')

      if (!roleSelect) {
        throw new Error('select[name="ai_prompt[prompt][][role]"] not found')
      }
      if (!contentTextarea) {
        throw new Error('textarea[name="ai_prompt[prompt][][content]"] not found')
      }

      promptData.push({
        role: roleSelect.value,
        content: contentTextarea.value
      })
    })
    this.promptJsonTarget.value = JSON.stringify(promptData)
  }

  submitForm(event: Event): void {
    event.preventDefault()
    this.updatePromptJson()
    const form = event.target as HTMLFormElement
    form.submit()
  }
}